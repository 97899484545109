

const Services = () => {

    
    return (
        <section className="services">
            <div className="container">
                <div className='card'>
                    <h1>I WILL DRUM 4 U</h1>
                    <div className='card-wrapper'>
                        <div className='left'>
                            <p>Now you can get the best fills in the biz on your record, home recording project or car commercial you're making for your uncle's used car lot!</p>
                            <p> Just email me for rates and general inquiries and if the vibes are right...</p>
                            <p>I'll send you the tracks and voila! Mid tier indie cred is sure to follow.</p>
                            <p>Give me a shout:</p>
                            <h2>jakemorrisdrums@gmail.com</h2>
                        </div>
    
                        <div className='right'>
                            <img src="/modernjake.png"></img>
                        </div>
                    </div>


                </div>

            </div>
        </section>
    )
}

export default Services;