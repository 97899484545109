


const Home = () => {

    return (
        <section className="home">
            <div className="jake-bg">
                <div className="container logo-wrapper">
                        <h1>Jake Morris</h1>
                </div>
                <img src="/jake3.jpg"></img>
            </div>

        </section>
    )
}

export default Home;